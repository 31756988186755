<template>
  <div></div>
</template>
<script>
import { Notification } from 'uiv';
export default {
  beforeMount() {
    localStorage.removeItem('isLoggedIn');
    Notification.notify({
      title: 'Logout',
      type: 'info',
      content: 'Good Bye !',
      duration: 5000,
    });
    this.$router.push('/login');
  }
}
</script>
