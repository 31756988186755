<template>
  <div id="settings">
    <Loading v-if="loading?.settings" />
    <div class="container">
      <div class="row" v-if="!loading?.settings">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">Health Monitor Settings</div>
              <div class="panel-body">

                <Tabs>
                  <Tab title="General">
                  <Form @submit="handleSave" :validation-schema="schemaTab1" v-slot="{ errors }">
                    <div class="p-20">
                      <label for="">Watching Address</label>
                      <div class="setting-input form-group" :class="{ 'has-error': errors.currentAddress, 'has-success': !errors.currentAddress }">
                        <label>Current Watching Address</label>
                        <Field class="form-control" placeholder="Please a valid address 0x..." 
                           v-model="formSettings.currentAddress" name="currentAddress" />
                        <ErrorMessage class="help-block has-error" name="currentAddress" />
                      </div>

                      <hr>

                      <label for="">Heath Level</label>
                      <!-- ding -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.dingLevel, 'has-success': !errors.dingLevel }">
                        <label>Ding</label>
                        <Field class="form-control" placeholder="Ding level" 
                           v-model="formSettings.healthLevel.ding" name="dingLevel" />
                        <ErrorMessage class="help-block has-error" name="dingLevel" />
                      </div>
                      <!-- alarm -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.alarmLevel, 'has-success': !errors.alarmLevel }">
                        <label>Alarm</label>
                        <Field class="form-control" placeholder="Alarm level" 
                           v-model="formSettings.healthLevel.alarm" name="alarmLevel" />
                        <ErrorMessage class="help-block has-error" name="alarmLevel" />
                      </div>

                      <hr>
                    
                      <label for="">Network Outage</label>
                      <!-- networkOutage.limit -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.networkLimit, 'has-success': !errors.networkLimit }">
                        <label>Limit</label>
                        <Field class="form-control"  v-model="formSettings.networkOutage.limit" name="networkLimit" />
                        <ErrorMessage class="help-block has-error" name="networkLimit" />
                      </div>
                      
                      <!-- pingHealthCheckUid -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.pingHealthCheckUid, 'has-success': !errors.pingHealthCheckUid }">
                        <label>Ping Health Check UID</label>
                        <Field class="form-control"  v-model="formSettings.pingHealthCheckUid" name="pingHealthCheckUid" />
                        <ErrorMessage class="help-block has-error" name="pingHealthCheckUid" />
                      </div>

                      <hr>
                    
                      <label for="">Timer</label>
                      <!-- timer.delayBetweenChecksMs -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.timerDelay, 'has-success': !errors.timerDelay }">
                        <label>Delay between checks in seconds</label>
                        <Field class="form-control" v-model="formSettings.timer.delayBetweenCheckSeconds" name="timerDelay" />
                        <ErrorMessage class="help-block has-error" name="timerDelay" />
                      </div>                    
                    </div>
                    <button type="submit" class="btn btn-primary">Save Settings</button>
                    </Form>
                  </Tab>

                  <!-- Notifications Tab -->

                  <Tab title="Notifications">
                    <Form @submit="handleSave" :validation-schema="schemaTab2" v-slot="{ errors }">
                    <div class="p-20">
                      <label for="">Telegram Notifications</label>

                      <div v-if="formSettings?.notifications?.telegram?.setup === 'done'">
                        <span class="badge badge-info">
                          Telegram Setup Done
                        </span>
                      </div>
                      <!-- telegramToken -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.telegramToken, 'has-success': !errors.telegramToken }">
                        <label>Telegram Token</label>
                        <Field class="form-control"  v-model="formSettings.notifications.telegramToken" name="telegramToken" />
                        <ErrorMessage class="help-block has-error" name="telegramToken" />
                      </div>
                      
                      <div class="btn-group" v-if="formSettings.notifications.telegramToken">
                        <button @click="setupTelegram" class="btn btn-info">Setup Bot</button>
                        <button v-if="formSettings.notifications?.telegram?.setup === 'done'" @click="testTelegramNotif" class="btn btn-info">Test Notification</button>
                      </div>

                      <hr>

                      <label for="">Push Over Notifications</label>

                      <!-- App Token -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.pushoverAppToken, 'has-success': !errors.pushoverAppToken }">
                        <label>App Token</label>
                        <Field class="form-control" v-model="formSettings.notifications.pushover.appToken" name="pushoverAppToken" />
                        <ErrorMessage class="help-block has-error" name="pushoverAppToken" />
                      </div>

                      <!-- User Token -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.pushoverUserToken, 'has-success': !errors.pushoverUserToken }">
                        <label>User Token</label>
                        <Field class="form-control" v-model="formSettings.notifications.pushover.userToken" name="pushoverUserToken" />
                        <ErrorMessage class="help-block has-error" name="pushoverUserToken" />
                      </div>
                      <!-- Priority  -->
                      <div class="setting-input form-group" >
                        <label>Priority</label>
                        <Dropdown menu-right>
                          <Btn class="dropdown-toggle">{{ priorityList.filter(el => el.value== settings.notifications?.pushover?.priority )?.[0]?.label || 'Select Priority' }} <span class="caret"></span></Btn>
                          <template #dropdown>
                            <li v-for="(priority, key) in priorityList" :key="key" ><a @click="formSettings.notifications.pushover.priority = priority.value" role="button">{{ priority.label }}</a></li>
                          </template>
                        </Dropdown>

                      </div>
                      <div style="clear:both;"></div>
                      <div class="p-20" style="margin:20px 0px;" 
                        v-if="formSettings.notifications.pushover.priority && formSettings.notifications.pushover.priority == 2">
                          <div class="setting-input form-group" :class="{ 'has-error': errors.pushoverRetry, 'has-success': !errors.pushoverRetry }">
                            <label>Retry in seconds on pushover<span><small> at least 30s (minimum)</small></span></label>
                            <Field class="form-control" v-model="formSettings.notifications.pushover.retry" name="pushoverRetry" />
                            <ErrorMessage class="help-block has-error" name="pushoverRetry" />
                          </div>
                          <div style="clear:both;"></div>
                          <div class="setting-input form-group" :class="{ 'has-error': errors.pushoverExpire, 'has-success': !errors.pushoverExpire }">
                            <label>Expiry in seconds on pushover<span><small> max 10800s (3h) (maximum)</small></span></label>                            
                            <Field class="form-control" v-model="formSettings.notifications.pushover.expire" name="pushoverExpire" />
                            <ErrorMessage class="help-block has-error" name="pushoverExpire" />
                          </div>
                      </div>

                      <div style="clear:both;"></div>

                      <div class="btn-group" v-if="formSettings.notifications?.pushover && formSettings.notifications.pushover.appToken && formSettings.notifications.pushover.userToken">
                        <button @click="testPushOverNotif" class="btn btn-info">Test Push Over Notification</button>
                      </div>

                      <hr>

                      <label for="">Custom Message Notification</label>

                      <!-- customMessage -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.customMessage, 'has-success': !errors.customMessage }">
                        <label>Custom Message</label>
                        <Field class="form-control" name="customMessage" v-slot="{ field }" v-model="formSettings.notifications.customMessage">
                          <textarea v-bind="field" name="customMessage" class="form-control" rows="4" cols="10" aria-label="Custom Message">
                          </textarea>
                        </Field>
                        <ErrorMessage class="help-block has-error" name="customMessage" />
                      </div>

                    </div>
                    <button type="submit" class="btn btn-primary">Save Settings</button>
                    </Form>
                  </Tab>
                  
                  <Tab title="App Settings">
                    <Form @submit="handleSave" v-slot="{ errors }" :validation-schema="schemaTab3">
                    <div class="p-20">
                      <!-- Infura Url -->
                      <div class="setting-input form-group" :class="{ 'has-error': errors.infuraUrl, 'has-success': !errors.infuraUrl }">
                        <label>Infura App Endpoint Or Infura Api Key</label>
                        <Field class="form-control" v-model="formSettings.infuraUrl" name="infuraUrl" />
                        <ErrorMessage class="help-block has-error" name="infuraUrl" />
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary">Save Settings</button>
                    </Form>
                  </Tab>

                </Tabs>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useStore } from '@/store/index';
import Loading from '@/components/Loading';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { object, string, number } from 'yup';
import { Notification } from 'uiv';

export default {
  name: "Settings",
  components: { Loading, Form, Field, ErrorMessage },
  data() {
    return {
      priorityList: [
        { label: '-2 (Lowest, no alert)', value: -2 },
        { label: '-1 (Low, no sound)', value: -1 },
        { label: 'Normal', value: 0 },
        { label: '+1 (High Priority, bypass Quiet Hours)', value: 1 },
        { label: '+2 (Emergency, repeat until acknowleged)', value: 2 },
      ],
      schemaTab3: object({
        infuraUrl: string().required(),
      }),
      schemaTab1: object({
        currentAddress: string().required().min(42).max(42),
        dingLevel: number().required(),
        alarmLevel: number().required(),
        networkLimit: number().required(),
        timerDelay: number().required(),
      }),
      formSettings: {
        healthLevel: {
          ding: null,
          alarm: null
        },
        networkOutage: {
          limit: null
        },
        timer: {
          delayBetweenChecksMs: null,
          delayBetweenChecksSeconds: null,
          periodicChecker: {
            timeLimit: null
          }
        },
        notifications: {
          telegramToken: null,
          telegram: {},
          pushover: {},
          customMessage: null,
        },
        currentAddress: null,
        pingHealthCheckUid: null
      }
    }
  },
  async created() {
    const settings = await this.getUserSettings();
    for(let key in settings) {
      this.formSettings[key] = this.settings[key];
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(useStore, ['settings', 'addresses', 'messageAlert', 'loading']),
    schemaTab2() {
      let validationObj = {
          pingHealthCheckUid: string().nullable(),
          telegramToken: string().nullable(),
          pushoverAppToken: string().nullable(),
          pushoverUserToken: string().nullable(),
          pushoverRetry: number().nullable().min(30).max(10800),
          pushoverExpire: number().nullable().min(0).max(10800),
          customMessage: string().required(),
      }
      if(this.formSettings.notifications.pushover.priority !== 0 && this.formSettings.notifications.pushover.priority == 2) {
        validationObj = {
          ...validationObj,
          pushoverRetry: number().required().min(30).max(10800),
          pushoverExpire: number().required().min(0).max(10800),
        }
      }

      return object(validationObj)
    }
  },
  methods: {
    ...mapActions(useStore, ['getUserSettings', 'updateUserSettings', 'getCronJobs', 'setupTelegramBot', 'testNotification']),

    async refreshSettings() {
      await this.getUserSettings();
      for(let key in this.settings) {
        this.formSettings[key] = this.settings[key];
      }
    },

    selectPriority(priority) {
      this.formSettings.notifications.pushover.priority = priority
    },

    validateSetting(value, key, type) {
      if(type==='float' || type==='number') {
        value = Number(value)
      }
      if(type==='integer') {
        value = Number(parseInt(value))
      }
      if(type==='string') {
        value = String(value)
      }
      if(key.split('.').length==2) {
        this.formSettings[key.split('.')[0]][key.split('.')[1]] = value
      }
      if(key.split('.').length==3) {
        this.formSettings[key.split('.')[0]][key.split('.')[1]][key.split('.')[2]] = value
      }
    },

    async handleSave() {
      this.updateUserSettings(this.formSettings);
      await Notification.notify({
        title: 'Settings Update',
        type: 'success',
        content: 'Settings has been updated.'
      });
    },

    async setupTelegram() {
      await this.handleSave();
      await this.setupTelegramBot();
      await this.getUserSettings();
    },

    async testTelegramNotif() {
      await this.handleSave();
      await this.testNotification('telegram');
    },
    
    async testPushOverNotif() {
      await this.handleSave();
      await this.testNotification('pushover');
    }

  },
}
</script>
<style scoped>

.setting-input {
  margin: 10px 0px;
}
.priority {
    position: absolute;
    width: 45%;
    left: 45.5%;
}
.setting-input label {
  font-weight: normal;
  width: 45%;
  float: left;
}

.setting-input textarea,
.setting-input input {
  width: 50%;
}

.p-20 { padding: 20px; }

.actions {
  margin-top: 25px;
}
</style>