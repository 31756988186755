<template>
  <div className="loading-container">
    <span className="loader"></span>
  </div>
</template>
<style>
.loading-container {
  width: 100%;
  height: 90vh;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  background: rgb(247 246 242 / 20%);
  position: relative;
  z-index: 1;
}

.loader {
  width: 48px;
  height: 48px;
  border: 4px solid #4b6587;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 45vh;
  left: 47vw;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 
</style>