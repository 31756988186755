<template>
  <div id="app">
    <main>
      <div v-if="isLoggedIn">
        <div class="container">
          <LineChart :chartData="formattedChartData" />

          <div class="col-lg-6">
            <h1 
              id="authorizeaudio"
              @click="startMonitoring"
              style="cursor: pointer;"
              @load="this.clicked = false"
              :class="{ 'green': clicked, 'red': !clicked, 'black': !(lastSync && lastSync?.health) }"
            >
              {{ clicked? "&check; Listening..." : !(lastSync && lastSync?.health) ? "Loading ..." : "&#x2715; Click Here to Start Listening" }}
            </h1>

            <div 
              @click="toggleBackendMonitoring" class="backend-btn" 
              :class="{ 'green': cronTaskEnabled, 'red': !cronTaskEnabled, 'black': !(lastSync && lastSync?.health) }">
              <span>{{ cronTaskEnabled ? "&check; Backend Monitoring Running" : !(lastSync && lastSync?.health) ? "Loading ..." : "&#x2715; Click Start Monitoring"}}</span>
            </div>

            <h1 v-if="errorlog" id="errorlog" style="cursor: pointer;color: red">{{ errorlog }}</h1>
            <span v-if="lastSync && lastSync.timestamp" id="lastupdated" v-tooltip.right="isConnected ? `Connected to server`: `Disconnected from server`">
              <strong>Last Sync</strong>: {{ new Date(lastSync.timestamp).toLocaleString() }}
              <span :class="{ 'connected': isConnected, 'disconnected': !isConnected  }" ></span>
            </span>
            <span v-else><strong>Last Sync</strong>: {{ 'Loading ...' }}</span>
            
            <div id="health" v-if="lastSync && lastSync?.health">
              <br>
              <div v-for="(item, index) in lastSync.health" :key="index">
                <br>
                <!-- <span>{{ getCapitalized(index) }} Health</span> -->
                <span>Health</span>
                <br>
                <span
                  id="health_ethereum"
                  class="health"
                  style="font-size: 36px;"
                  :style= "[lastSync?.health[index] > settings.healthLevel['ding'] ? 'color: green' : lastSync?.health[index] > settings.healthLevel['alarm'] ? 'color: darkorange' : 'color: red']"
                >{{ lastSync.health[index] }}</span>

              </div>
            </div>

            <!-- check if there's any data on the first network in queue -->
            <div v-if="!lastSync.health"  style="font-size: 36px;">Loading...</div>
            
          </div>

          <div class="col-lg-6">

            <LogsList :logs="backendLogs" />

          </div>
        </div>
      </div>

    </main>
  </div>
</template>


<script>

import { playAudio, loadAudio, dateBuilder } from '../helpers';
import { mapWritableState, mapActions, mapState } from 'pinia';
import { useStore } from '@/store';
import { useUserStore } from '@/store/user';
import LineChart from '@/components/LineChart.vue';
import LogsList from '@/components/LogsList.vue';
import { io } from "socket.io-client";

export default {
  name: "Home",
  data() {
    return {
      lastHealth: null,
      logSocket: [],
      taskCheckInterval: null
    };
  },
  components: {
    LineChart, LogsList
  },
  computed: {
    ...mapState(useUserStore, ['isLoggedIn']),
    ...mapState(useStore, ['isLoading', 'listJobs', 'loading']),
    ...mapWritableState(
      useStore, 
      ['isConnected', 'socket', 'logs', 'health', 'settings', 'clicked', 'backendMonitoring', 'errorlog', 'chartData', 'backendLogs', 'healthLogs']
    ),
    lastSync() {
      if(this.lastHealth) {
        return { health: {'etheruem': this.lastHealth.value }, timestamp: this.lastHealth.timestamp }
      }
      if(this.healthLogs) {
        const level = Object.values(this.healthLogs).sort((a,b) => new Date(a.timestamp) < new Date(b.timestamp) ? 1:-1)?.[0];
        // console.log(level)
        if(level) {
          // document.title = level?.value?.toFixed(4) + ' - Health Alerter';
          return {
            health: {'etheruem': level?.value },
            timestamp: level.timestamp
          };
        }
        return {}
      }
      return {};
    },
    cronTaskEnabled() {
      if(this.listJobs) {
        return this.listJobs?.includes('healthMonitorTask')
      }
      return false
    },
    timer() {
      return this.settings.timer
    },
    networks() {
      return this.settings.networks
    },
    formattedChartData() {
      let labels = [];
      let data = [] ;

      let dingData = [];
      let alarmData = [];

      if(this.healthLogs) {
        let values = Object.values(this.healthLogs);
        if (this.isiOS()) {
          values = values.reverse();
        }
        values.sort((a,b) => new Date(a.timestamp) < new Date(b.timestamp ? 1:-1 ) ).forEach(el => {
          labels.push(new Date(el.timestamp).toLocaleString())
          data.push(el.value)
          dingData.push(el.dingLevel)
          alarmData.push(el.alarmLevel)
        });
      }

      return {
        labels: labels,
        datasets: [
          {
            label: 'Health Level',
            backgroundColor: '#286090',
            borderColor: '#286090',
            borderWidth: 3,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            borderJoinStyle: 'round',
            borderCapStyle: 'miter',
            data: data
          },
          {
            label: 'Ding Level',
            backgroundColor: 'transparent',
            borderColor: 'orange',
            borderDash: [5],
            borderDashOffset: 10,
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            borderJoinStyle: 'round',
            borderCapStyle: 'miter',
            data: dingData
          },
          {
            label: 'Alarm Level',
            backgroundColor: 'transparent',
            borderColor: 'red',
            borderDash: [5],
            borderDashOffset: 10,
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            borderJoinStyle: 'round',
            borderCapStyle: 'miter',
            data: alarmData
          }
        ]
      }
    },
  },
  mounted() {
    this.getUserSettings();
    this.updateNow();

    this.startSocket();
    this.isConnected = this.socket?.connected;
    
    document.title = 'Health Alerter';
    //preload this sound in the background, so when there's no internet connection it can still play
    loadAudio('/sounds/network-outage.mp3');

  },
  methods: {
    dateBuilder,
    ...mapActions(useStore, ['getCronJobs', 'getUserSettings', 'cronMonitor', 'updateUserSettings', 'getLogs', 'getHealthFactorLogs']),
    toggleBackendMonitoring() {
      // starting backend monitoring ! 
      if(!this.cronTaskEnabled) {
        this.cronMonitor('start');
        setTimeout(() => this.updateNow(), 1000);
        this.taskCheckInterval = setInterval(() => this.getCronJobs(), this.settings.timer.delayBetweenChecksSeconds*1000 || 60*1000 )
        playAudio('success');
      } else {
        this.cronMonitor('stop');
        if(this.taskCheckInterval) {
          clearInterval(this.taskCheckInterval);
        }
        setTimeout(() => this.updateNow(), 1000);
        playAudio('success');
      }
    },
    startMonitoring() {
      this.monitorHealth();
      this.clicked = true;
      playAudio('success');
    },
    monitorHealth() {
      if(!this.cronTaskEnabled) {
        this.cronMonitor('start');
        setTimeout(() => this.updateNow() , 1000);
        this.taskCheckInterval = setInterval(() => this.getCronJobs(), this.settings.timer.delayBetweenChecksSeconds*1000 || 60*1000 )
      }
    },

    updateNow() {
      // const logsOffset = this.backendLogs ? Object.values(this.backendLogs).length : 0;
      // const healthOffset = this.healthLogs ? Object.values(this.healthLogs).length : 0;
      this.getCronJobs();
      this.getLogs('all');
      this.getHealthFactorLogs('all');
    },

    startSocket() {
      const socket = io('https://4065.org/');

      socket.on('connect', () => {
        this.isConnected = true;
      });

      socket.on('disconnect', () => {
        console.log('disconnected !!!')
        this.isConnected = false;
      });


      socket.on('log', (...data) => {
        // console.log('socket log', this.lastHealth)
        this.backendLogs[Object.values(this.backendLogs).length] = JSON.parse(data);
      });

      socket.on('health', (...data) => {
        this.lastHealth = JSON.parse(data);
        // console.log('socket health', this.lastHealth)
        document.title = parseFloat(this.lastHealth.value).toFixed(4) + ' - Health Alerter';
        this.healthLogs[Object.values(this.healthLogs).length] = JSON.parse(data); 
      });

      this.socket = socket;

    },

    isiOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("iPhone") && "ontouchend" in document)
    }
  }
};
</script>

<style>

.black { color: black; }

.green { color: green; }

.red { color: red; }

.backend-btn {
  position: absolute;
  top: 65px;
  right: 20px;
}

.backend-btn:hover {
  cursor: pointer;
}

#lastupdated .connected, #lastupdated .disconnected {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  display: inline-block;
  margin-left: 7px;
  margin-bottom: 2px;
}
#lastupdated .connected {
  background: green;
} 
#lastupdated .disconnected {
  background: red;
}


</style>

