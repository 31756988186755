import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { useStore } from '@/store'
import '../bootstrap/css/bootstrap.min.css'
import * as uiv from 'uiv'
import { createPinia } from 'pinia'

const pinia = createPinia();

createApp(App).use(uiv).use(pinia).use(router).mount('#app');
