import { defineStore } from 'pinia';
import { errorMessage, fetchBackend } from '../helpers';

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: false,
    token: null,
    user_id: null,
    username: null,
    role: null,
  }),
  actions: {
    async login(user) {
      try {
        const { response, message } = await fetchBackend({ endpoint: 'api/v1/auth/login', method: 'POST', payload: user })
        if (message) {
          this.isLoggedIn = false;
          localStorage.removeItem('isLoggedIn');
          return false;
        }
        if (response) {
          this.token = response.accessToken;
          this.isLoggedIn = true;
          localStorage.setItem('isLoggedIn', true);
          return true;
        }
      } catch (error) {
        errorMessage(error);
      }
    },
    logout() {
      this.user_id = null
      this.username = null
      this.token = null
      this.role = null
      this.isLoggedIn = false
      localStorage.removeItem('isLoggedIn');
    },
    async verify() {
      try {
        const data = await fetchBackend({ endpoint: 'api/v1/auth/verify-user', method: 'POST' });
        if (data.message) {
          this.isLoggedIn = false;
          localStorage.removeItem('isLoggedIn');
          return { message: 'Authentication invalid' };
        } else {
          this.isLoggedIn = true;
          localStorage.setItem('isLoggedIn', true);
          this.user_id = data.user_id,
          this.username = data.username,
          this.role = data.role
          return { data };
        }
      } catch (error) {
        localStorage.removeItem('isLoggedIn');
        errorMessage(error);
      }
    }
  }
});
