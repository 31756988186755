import { createWebHistory, createRouter } from "vue-router";
import { useUserStore } from "@/store/user";
import Home from "@/views/Home.vue";
import Settings from "@/views/Settings.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      // to make it accessible just after login
      requiresAuth: true,
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// adding checking the authorization when changing route inside the app
router.beforeEach((to) => {
  // ✅ This will work because the router starts its navigation after
  const userStore = useUserStore();
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  if (to.meta.requiresAuth && !userStore.isLoggedIn && !isLoggedIn) {
    return '/login'
  }
  if (to.meta.requiresAuth && !userStore.isLoggedIn && isLoggedIn) {
    try {
      const request = userStore.verify();
      if (!request || request.message === 'Authentication invalid') {
        localStorage.removeItem('isLoggedIn');
        return '/login';
      }
      if (request.accessToken && userStore.isLoggedIn) {
        return true
      }
    } catch (e) {
      localStorage.removeItem('isLoggedIn');
      return '/login'
    }
  }
});

export default router;
