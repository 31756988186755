<template>
  <div id="app">
    <main>
      <MainNav :links="navigation" :isLoggedIn="isLoggedIn" v-on:logout="handleLogout"></MainNav>
      <router-view />
    </main>
  </div>
</template>
<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/store/user';
import MainNav from '@/components/MainNav';

export default {
  name: 'App',
  components: { MainNav },
  data() {
    return {
      refresh: null,
      navigation: [
        {
          label: 'Home',
          route: '/'
        },
        {
          label: 'Settings',
          route: '/settings'
        },
      ]
    }
  },
  computed: {
    ...mapState(useUserStore, ['isLoggedIn']),
  },
  methods: {
    handleLogout() {
      // redirect to /logout
      this.$router.push('/logout');
    }
  },
}

</script>

<style>
.navbar {
  margin-bottom: 20px;
}
</style>