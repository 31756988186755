<script>
import { defineComponent, h } from 'vue'

import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
)
/* Glogal */
ChartJS.defaultFontFamily = "'Nunito','Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
// ChartJS.legend.align = "end";
// ChartJS.tooltips.mode = "index";
ChartJS.defaults.intersect = false;
ChartJS.defaults.interaction.intersect = false;
ChartJS.defaults.maintainAspectRatio = false;
ChartJS.defaults.elements.line.borderCapStyle = "round";
// ChartJS.overrides.line.spanGaps = true;
// ChartJS.defaults.elements.line.cubicInterpolationMode = 'monotone';
/* Color scheme */
ChartJS.defaultFontColor = "rgba(0,0,0,0.3)";
/* GridLines */
// ChartJS.scale.color = "rgba(0,0,0,0.02)";

export default defineComponent({
  name: "LineChart",
  components: {
    Line
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [40, 39, 10, 40, 39, 80, 40]
            }
          ]
        }
      }
    }
  },
  setup(props) {
    // const chartData = props.data

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'index',
        intersect: true
      },
      intersect: true,
      scales: {
        x: {
          min: props.chartData.labels[0],
          max: props.chartData.labels[props.chartData.labels.length-1],
        }
      },
    }

    return () =>
      h(Line, {
        chartData: props.chartData,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins
      })
  }
})
</script>

