<template>
  <div>
    <div id="login" v-if="!loading">
      <div class="container">
        <div class="row">
            <div class="login col-md-6 col-md-offset-3 col-sm-10 col-sm-offset-1">
              <h4>Login</h4>
              <div class="card">

                <Form @submit="handleLogin" :validation-schema="schema">
                  <label for="password">Email</label>
                  <Field class="form-control" v-model="user.email" name="email" />
                  <ErrorMessage name="email" />
                  <p></p>
                  <label for="password">Password</label>
                  <Field class="form-control" v-model="user.password" name="password" type="password" />
                  <ErrorMessage name="password" />
                  <p></p>
                  <button type="submit" class="btn btn-primary">Login</button>
                </Form>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>
<script>

import { mapActions,  } from 'pinia';
import { useUserStore } from '@/store/user';
import { Notification } from 'uiv';
import Loading from '@/components/Loading';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'Login',
  components: { Loading, Form, Field, ErrorMessage },
  data() {
    return {
      schema: yup.object({
        email: yup.string().required().email(),
        password: yup.string().required().min(8),
      }),
      user: {
        email: '',
        password: ''
      },
      loading: null
    }
  },
  methods: {
    ...mapActions(useUserStore, ['login',]),
    async handleLogin() {
      this.loading = true;
      // maybe some input checks before requesting the auth service
      // for now it is just checking the length if it is greater than 3 chars then it requests the login function in the store
      if (this.user.password.length >= 6 && this.user.email.split('@')?.length == 2 && this.user.email.split('@')[1]?.split('.')?.length == 2 ) {
        const logged = await this.login(this.user);
        this.loading = false;
        if(logged) {
          Notification.notify({
            title: 'Login Success',
            type: 'success',
            content: 'Welcome back',
            duration: 5000,
          });

          this.$router.push('/');
        } else {
          Notification.notify({
            title: 'Error Logging In',
            type: 'danger',
            content: 'Please check credentials !',
            duration: 5000,
          });
          this.$router.push('/logout');
        }
      }
    },
  }
}
</script>
