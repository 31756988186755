<template>
  <Navbar>
    <template #brand>
      <a class="navbar-brand" href="#">Health Monitor</a>
    </template>
    <template #collapse>
      <NavbarNav>
        <!-- <li class="active">
          <a role="button">Link <span class="sr-only">(current)</span></a>
        </li> -->
        <li v-for="(item, index) in links" :key="index" :class="{'active': this.$route.path == item.route }" >
          <router-link :to="item.route">{{ item.label }}</router-link>
        </li>
      </NavbarNav>
      <NavbarNav right>
        <li v-if="isLoggedIn">
          <router-link to="/logout">Logout</router-link>
        </li>
        <li v-else>
          <router-link to="/login">Login</router-link>
        </li>
      </NavbarNav>
    </template>
  </Navbar>
</template>

<script>

export default {
  name: 'MainNav',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    logout() {
      this.$emit('logout');
    }
  }
}

</script>
