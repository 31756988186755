
import { defineStore } from 'pinia';
import { Notification } from 'uiv';
import { fetchBackend, getDefaultSettings, errorMessage } from '../helpers';

export const useStore = defineStore('store', {
  // other options...
  state: () => {
    return {
      loading: {},
      isConnected: null,
      socket: null,
      isLoading: null,
      pooling: null,
      listJobs: null,
      clicked: false,
      backendMonitoring: false,
      logs: "",
      health: {},
      settings: getDefaultSettings(),
      // list of addresses with labels and codes to use it later in a dropdown select. 
      addresses: [
        {
          label: "Aave V2",
          code: "aave-v2",
          address: "0xaf0fdd39e5d92499b0ed9f68693da99c0ec1e92e",
        }
      ],
      errorlog: null,
      chartData: {},
      messageAlert: null,
      backendLogs: null,
      healthLogs: null
    }
  },
  actions: {

    async getUserSettings() {
      try {
        this.loading.settings = true;
        const { settings } = await fetchBackend({endpoint: 'api/v1/settings/'});
        this.settings = settings;
        this.loading.settings = false;
        return settings
      } catch (error) {
        errorMessage(error);
      }
    },

    async updateUserSettings(localSettings) {
      try {
        this.loading.settings = true;
        const { settings } = await fetchBackend(
          { endpoint: 'api/v1/settings/update', method: 'POST', payload: { settings: localSettings } }
        );
        this.settings = settings;
        this.loading.settings = false;
        return true;
      } catch (error) {
        errorMessage(error);
        return false;
      }
    },
    async setupTelegramBot() {
      this.loading.notificationTelegram = true;

      const {status, settings} = await fetchBackend({ endpoint: 'api/v1/notifications/setup', method: 'POST' });
      this.loading.notificationTelegram = false;

      if (!status) {
        Notification.notify({
          title: 'Telegram Setup',
          type: 'warning',
          content: status?.message || 'Telegram bot not setuped, check token or contact the bot.',
          duration: 5,
        });
      }
      if (status && settings.notifications.telegram.setup === 'done'){
        Notification.notify({
          title: 'Telegram Setup',
          type: 'success',
          content: 'Telegram has been setuped successfully.',
          duration: 5,
        });
      }      
    },
    
    async testNotification(provider) {
      this.isLoading = true;

      const { status, request } = await fetchBackend({ endpoint: `api/v1/notifications/test/${provider}`, method: 'POST'});

      this.isLoading = false;

      if (!status) {
        Notification.notify({
          title: 'Test Notification',
          type: 'warning',
          content: status?.message || 'Telegram bot not setuped, check token or contact the bot.',
          duration: 5,
        });
      } else {
        Notification.notify({
          title: 'Test Notification',
          type: 'success',
          content: provider === 'telegram' ? 'Telegram has been sent. \n' : provider === 'pushover' ? 'Push Over notification sent ' + request : '',
          duration: 5,
        });
      }
    },

    async cronMonitor(startOrStop) {
      try {
        const data = await fetchBackend({ endpoint: `api/v1/cron/${startOrStop}`, method: 'POST'});
        return data;
      } catch (error) {
        errorMessage(error);
      }
    },

    async getCronJobs() {
      try {
        this.isLoading = true;
        const { jobs } = await fetchBackend({endpoint: 'api/v1/cron/list', method: 'POST'});
        this.listJobs = jobs;
        this.isLoading = false;
        return jobs;
      } catch (error) {
        errorMessage(error);
      }
    },

    async getHealthFactorLogs(offset=0) {
      try {
        this.loading.chart = true;
        const healthLogs = await fetchBackend({endpoint: 'api/v1/health/history', method: 'POST', payload: { offset: offset }});
        // if(offset>0) {
        //   // this.healthLogs = { ...healthLogs, ...this.healthLogs };
        //   Object.values(healthLogs).forEach(log => this.healthLogs.push(log))
        // } else {
        this.healthLogs = Object.values(healthLogs);
        // }
        this.loading.chart = false;  
      } catch (error) {
        errorMessage(error);
      }
    },

    async getLogs(offset=0) {
      try {
        this.loading.logs = true;
        const logs = await fetchBackend({endpoint: 'api/v1/logs/history', method: 'POST', payload: { offset: offset }});
        // if(offset > 0) {
        //   // this.backendLogs = { ...logs, ...this.backendLogs };
        //   Object.values(logs).forEach(log => this.backendLogs.push(log))
        // } else {
          this.backendLogs = Object.values(logs);
        // }
        this.loading.logs = false;
      } catch (error) {
        errorMessage(error);
      }
    }
  },
});
