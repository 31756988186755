
export function dateBuilder() {
    return new Date().toLocaleString();
}

export function getCapitalized(desc) {
    desc = desc.charAt(0).toUpperCase() + desc.substr(1, desc.length).toLowerCase();
    return desc;
}

export function playAudio(message) {
    let audiofile = '';
    if (message == 'alarm') {
        audiofile = '/sounds/alarm.mp3';
    } else if (message == 'ding') {
        audiofile = '/sounds/ding.wav';
    } else if (message == 'success') {
        audiofile = '/sounds/success-sound-effect.mp3';
    } else if (message == 'network-outage') {
        audiofile = '/sounds/network-outage.mp3';
    } else if (message == 'alarm-beeping') {
        audiofile = '/sounds/alarm-beeping.mp3';
    } else {
        console.log('nothing found');
    }

    const audio = loadAudio(audiofile);
    audio.play();
}

export function loadAudio(audiofile) {
    return new Audio(audiofile);
}



// default settings

export function getDefaultSettings() {
    const settings = {
        networks: [
            "ethereum",
            // "polygon",
            // "avalanche",
        ],
        healthLevel: {
            ding: 1.15,
            alarm: 1.10
        },
        networkOutage: {
            limit: 120
        },
        timer: {
            delayBetweenChecksSeconds: 60,
            delayBetweenChecksMs: 60 * 1000,
            periodicChecker: {
                timeLimit: 86400000,
                timePassed: 0,
            }
        },
        currentAddress: null,
        notifications: {
            telegramToken: null,
            telegram: {},
            pushover: {},
            customMessage: null
        }
    };
 
    return settings;
}

import { Notification } from 'uiv';


export async function fetchBackend({endpoint, method=null, payload=null}) {
    const API_ENDPOINT = '/'
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    let params = {
        method: method || 'GET',
        headers: headers,
        credentials: 'include',
    }
    if(payload) {
        params = { ...params, body: JSON.stringify(payload) }
    }

    try {
        const response = await fetch(API_ENDPOINT + endpoint, params);
        const result = await response.json();
        return result;
    } catch (error) {
        errorMessage(error)
        return false; 
    }
}


export function errorMessage(error) {
    // if there's an error, log it
    Notification.notify({
        title: error?.title || 'Network Error',
        type: 'danger',
        content: String(error.message || error),
        duration: 0,
    });
    playAudio('network-outage');
}