<template>
  <div class="logs-wrapper">

    <div class="logs" v-if="logs">
      <div 
        v-for="log in logsArray" :key="log.id" 
        class="logItem" 
      >
        <div v-if="log && log.details" v-tooltip.left="log.title" :class="{'is-success': log.type==='success', 'is-danger': log.type==='danger'}">
          <span v-if="log?.details?.blockNumber" class="block-number">#{{ log.details.blockNumber }}</span>
          <span class="label" :class="{
            'label-danger': log.type === 'danger' || log.type === 'warning' || log?.details?.state === 'stoped',
            'label-success': log.type === 'success' || log?.details?.state === 'started' 
          }">{{ log.details.state }}</span>
          <span class="date">{{ new Date(log.timestamp).toLocaleString() }}</span>
          <span v-if="log.details.healthFactor" class="health"> Health at : {{ parseFloat(log.details.healthFactor).toFixed(6) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['logs'],
  computed: {
    logsArray() {
      if(this.logs) {
          const logs = Object.values(this.logs).sort((a, b) => {
            return (new Date(a.timestamp) < new Date(b.timestamp)) ? 1 : -1 ; 
          });
          return logs;
      }
      return [];
    }
  }
}
</script>

<style scoped>
.logs-wrapper {
  margin-top: 40px;
  max-height: 500px;
  overflow: scroll;
}

.logItem {
  padding: 5px;
  font-size: 100%;
}

.logItem span {
    margin: 0px 5px;
}

.logItem .state:before {
  content: '';
  display: 'inline-block';
  width: 5px;
  height: 5px;
  background: #aaaaaa;
}

.logItem {
    border-bottom: 1px solid #aaaaaa;
}
</style>